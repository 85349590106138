import React, { useState } from 'react'
import logo from './home/logo.png';
import logo512 from './home/logo512.png';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Import Bootstrap JS
function Header() {
	const [menuOpen, setMenuOpen] = useState(false);

	const onToggleMenu = () => {
		setMenuOpen(true);
	};
	const onCloseMenu = () => {
		setMenuOpen(false); // Close the menu
	};

	return (
		<div className='font-sans'>
			<div className='bg-[#323232] text-center p-2 text-white text-sm' ><span>Providing you with quality flooring, paints, and expert design advice for over 60 years!</span></div>
			<div class="bg-white ">
				<div class=" px-2">

					<div class="flex items-center justify-center pt-4 pb-2">

						<div class="hidden sm:flex sm:items-center sm:w-[20%]">	
							<p class="text-[#803333] text-sm font-bold hover:text-slate-600 mr-4">O'Fallon , MO <br /> <a href="tel:+16362405220" className='text-[#803333]'> <i class="bi bi-telephone"> (636) 240-5220</i> </a></p>	</div>

						<div className=" flex sm:w-[60%] item-center justify-center " >
							<a href="/" class="d-block " ><img src={logo} className="h-[150px]"alt="Los Angeles" /> </a>


						</div>



						<div class="hidden sm:flex sm:flex-col sm:items-center ">
							<div class="text-black flex  text-sm font-semibold border px-4 py-1 rounded-lg hover:text-purple-600 hover:border-purple-600" style={{ backgroundColor: '#803333' }}><a href='/products' className='text-white p-3'>Products </a></div>
							<div className="">
								<span className="text-base flex-col  font-bold tracking-wide text-center text-[#803333]">Follow us on social media!</span>
								<div className="flex items-center justify-center mt-1 space-x-3">
									<a
										href="https://www.facebook.com/ofallondecorating/"
										className="text-[#803333] transition-colors duration-300 hover:text-deep-purple-accent-400"
									>
										<i className="bi bi-facebook"></i>
									</a>
									<a
										href="https://www.yelp.com/biz/o-fallon-decorating-o-fallon"
										className="text-[#803333] transition-colors duration-300 hover:text-deep-purple-accent-400"
									>
										<i className="bi bi-yelp"></i>
									</a>
									<a
										href="https://www.instagram.com/ofallondecorating/"
										className="text-[#803333] transition-colors duration-300 hover:text-deep-purple-accent-400"
									>
										<i className="bi bi-instagram"></i>
									</a>
								</div>
							</div>
						</div>


					</div>
					<div class="flex items-center lg:order-2 pb-2 px-3">
						<button onClick={onToggleMenu}
							name={menuOpen ? 'close' : 'menu'}
							className="text-3xl cursor-pointer md:hidden">
							<span class="sr-only">Open main menu</span>
							<svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
							<svg class="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>

						</button>

					</div>



					<div
						className={`nav-links duration-500 md:static   text-black    left-0 ${menuOpen ? 'top-[9%] bg-white relative' : 'top-[-100%] items-center justify-content-center absolute'
							} md:w-auto w-full flex  `}
					>
						<ul className="flex md:flex-row flex-col md:items-center  md:justify-content-center p-2 border-y-2 border-slate-700 font-sans">

							<li className={`md:hidden ${menuOpen ? 'md:hidden' : 'md:hidden'}`}>
								<button class="text-black   font-semibold hover:text-purple-600 " onClick={onCloseMenu}>
									<i class="bi bi-x-square-fill"></i>
								</button>
							</li>
							{/* <li>
								<a href="/specials" class="text-black   btn px-3">Specials</a>
							</li> */}
							<li className="nav-item">
								<div className="btn-group dropend">
									<button
										type="button"
										className="btn  dropdown-toggle font-bold text-black"
										data-bs-toggle="dropdown"
										aria-expanded="false"
									>
										<span className="text-bold">Products</span>
									</button>
									<ul className="dropdown-menu">
										<li>
											<a href="/products" class=" dropdown-item hover:text-green-900 ">All Products</a>
										</li>
										<li>
											<a href="/products/carpet" class=" dropdown-item hover:text-green-900 ">Carpet</a>
										</li>
										<li>
											<a href="/products/hardwood" class=" dropdown-item hover:text-green-900 ">Hardwood</a>
										</li>
										<li>
											<a href="/products/laminate" class=" dropdown-item hover:text-green-900 ">Laminate</a>
										</li>
										<li>
											<a href="/products/Tile" class=" dropdown-item hover:text-green-900 ">Tile</a>
										</li>
										<li>
											<a href="/products/luxuryvinyl" class=" dropdown-item hover:text-green-900 ">luxury Vinyl</a>
										</li>
										<li>
											<a href="/products/glasstile" class=" dropdown-item hover:text-green-900 ">Glass Tile</a>
										</li>
										<li>
											<a href="/blinds" class=" dropdown-item hover:text-red-700 ">Blinds</a>
										</li>
										<li>
											<a href="/ppg-paints" class=" dropdown-item hover:text-red-700 ">PPG-Paints</a>
										</li>


									</ul>
								</div>
							</li>
							<li>
								<a href="/flooring-services" class="text-black btn font-bold px-3"><span className="text-bold">Services</span></a>
							</li>
							<li>
								<a href="/reviews" class="text-black btn font-bold px-3"><span className="text-bold">Reviews</span></a>
							</li>
							<li>
								<a href="/inspiration" class="text-black  btn px-3"><span className="text-bold">Inspiration</span></a>
							</li>
							<li>
								<a href="/about-us" class="text-black  btn px-3"><span className="text-bold">About</span></a>
							</li>
							<li>
								<a href="/contact" class="text-black  btn px-3"><span className="text-bold">Contact Us</span></a>
							</li>
							<li >
								<a href="/free-estimate" class=" text-black  btn px-3"><span className="text-bold">Request an Estimate</span></a>

							</li>


						</ul>
					</div>
				</div>




			</div>


		</div>
	)
}

export default Header
