import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import myImage from './Component/google-review.png';
function Reviews() {
  return (
    <div>
      <div><Headers /> </div>
      <div className='container'>
        <div className='text-center font-bold '>
          <span style={{ fontSize: '35px' }}>Reviews</span> <br />
          <small className='justify'> HOW DID WE DO? LEAVE US A REVIEW BELOW!</small>
          <div class="flex items-center justify-center ">
            <a href="https://www.google.com/maps/place/O'Fallon+Decorating+Center/@38.8034091,-90.7213326,15z/data=!4m8!3m7!1s0x87ded0643d68b703:0xe0ec3cbd16d545a6!8m2!3d38.8034091!4d-90.7213326!9m1!1b1!16s%2Fg%2F1tf6606d?entry=ttu" target="_blank" rel="noreferrer">
              <img src={myImage} alt="Los Angeles" />
            </a>
          </div>
        </div>

        <section >
          <div>



          </div>
        </section >
      </div>
      <div><Footer /> </div>
    </div>
  )
}

export default Reviews
