import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import Request from './Component/requestEstimate';
import d1 from './Component/d1.png';
import d2 from './Component/d2.png';
import d3 from './Component/d3.png';
import brand1 from './Component/home/brand1.png';
import brand2 from './Component/home/brand2.png';
import s11 from './Component/s16.png';

function About() {
  return (
    <div>
      <div><Headers /> </div>
      <section className="hero-wrap hero-wrap-2" style={{ backgroundImage: `url(${s11})` }} data-stellar-background-ratio="0.5">
                <div className="overlay" style={{ background: 'rgba(0, 0, 0, 0.3)' }}>
                    <div className="container">
                        <div className="row no-gutters slider-text align-items-end justify-content-center">
                            <div className="col-md-9 ftco-animate mb-2 text-center p-5 text-white ">
                                <h2 className=" "><div className='text-white  capitalize'>Design Services</div></h2>

                                <a href='/free-estimate'> <div className='btn btn-dark '>Schedule Today</div> </a>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <div className='container px-4 py-2'>
  <section className='container'>
    <div className="row" style={{ paddingTop: '60px' }}>
      <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <img src={d1} alt="Los Angeles" className="d-block rounded border-solid border-[#803333] border-2" />
      </div>
      <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <a href='tel:+14178472484'>
          <img src={d2} alt="Los Angeles" className="d-block rounded border-solid border-[#803333] border-2" />
        </a>
      </div>
      <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <img src={d3} alt="Los Angeles" className="d-block rounded border-solid border-[#803333] border-2" />
      </div>
    </div>
  </section>
</div>

      
      <div><Request /></div>

      <section className='p-4 container border-t-2 py-2'>
        <div className='text-center font-bold'>
          <span style={{ fontSize: '25px' }}>Featured brands & technologies</span>
        </div>
        <section>
					<div >
							<img
								src={brand1}
								alt="brands"
								className="mx-auto "
							/>
						</div>
						<div >
							<img
								src={brand2}
								alt="brands"
								className="mx-auto"
							/>
						</div>
			</section>

      </section>
      <div><Footer /> </div>
    </div>
  )
}

export default About
