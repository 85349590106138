import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import Request from './Component/requestEstimate';
import home6 from './Component/home/home3.jpg';
import home3 from './Component/home1.png';
import home1 from './Component/home/home1.jpg';
import home2 from './Component/home/home2.jpg';
import home4 from './Component/home3.jpg';
import brand1 from './Component/home/brand1.png';
import PPG_Paints from './Component/home/PPG_Paints.png';
import i1 from './Component/i1.png';
import carpet from './Component/product/h1.png';
import hardwood from './Component/product/h2.png';
import laminate from './Component/product/h3.png';
import Tile from './Component/product/h4.png';
import NewHome from './Component/newhome.png'
import Hh1 from './Component/home/hh1.png';
import Hh2 from './Component/home/hh2.png';

function Home() {
	// const t = "LM12";

	return (
		<div >


			<Headers />
			{/* <div> <a href='/contact'> <img src= {require('./Component/photo/' + t + '.png')} alt="Los Angeles" class="d-block img-fluid" /></a></div> */}
			<section>
				<a href='/contact'>
					<img
						src={home3}
						alt="Flooring, furniture and hardware needs"
						className="mx-auto"
					/>
				</a>

			</section>


			<section className='  p-4' >
				<div className='container text-center'>
					<h1 class=" text-3xl   leading-none tracking-tight text-gray-900 md:text-4xl lg:text-4xl "> Looking For New Floors In The O'Fallon, MO Area?</h1>
					<p class="mb-6 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400"> SHOP OUR EXTENSIVE FLOORING CATALOG</p>
					<div class="row flex justify-content-center ">
						<div class="col-md-2 col-lg-2 col-6 p-2 justify-center bg-white " style={{ height: '100%', background: 'rgba(0, 0, 0, 0.7)', opacity: 1 }}>
							<img src={carpet} alt="Los Angeles" class=" w-100 h-75 rounded object-cover object-center" />
							<a href='/products/carpet'> <div className='btn btn-dark w-100' style={{ backgroundColor: '#803333', borderRadius: '8px' }}> Carpet</div> </a>
						</div>
						<div class="col-md-2 col-lg-2 col-6 p-2 justify-center bg-white " style={{ height: '100%', background: 'rgba(0, 0, 0, 0.7)', opacity: 1 }}>
							<img src={hardwood} alt="Los Angeles" class=" w-100 h-75 rounded object-cover object-center" />
							<a href='/products/hardwood'> <div className='btn btn-dark w-100' style={{ backgroundColor: '#803333', borderRadius: '8px' }}>Hardwood</div> </a>
						</div>
						<div class="col-md-2 col-lg-2 col-6 p-2 justify-center bg-white " style={{ height: '100%', background: 'rgba(0, 0, 0, 0.7)', opacity: 1 }}>
							<img src={laminate} alt="Los Angeles" class=" w-100 h-75 rounded object-cover object-center" />
							<a href='/products/luxuryvinyl'> <div className='btn btn-dark w-100' style={{ backgroundColor: '#803333', borderRadius: '8px' }}>Luxury-vinyl</div> </a>
						</div>
						<div class="col-md-2 col-lg-2 col-6 p-2 justify-center bg-white " style={{ height: '100%', background: 'rgba(0, 0, 0, 0.7)', opacity: 1 }}>
							<img src={Tile} alt="Los Angeles" class=" w-100 h-75 rounded object-cover object-center" />
							<a href='/products/Tile'> <div className='btn btn-dark w-100' style={{ backgroundColor: '#803333', borderRadius: '8px' }}> Tile</div> </a>
						</div>
						<div class="col-md-2 col-lg-2 col-6 p-2 justify-center bg-white " style={{ height: '100%', background: 'rgba(0, 0, 0, 0.7)', opacity: 1 }}>
							<img src={PPG_Paints} alt="Los Angeles" class="  w-100 h-75 rounded object-cover object-center" />
							<a href='/ppg-paints'> <div className='btn btn-dark w-100' style={{ backgroundColor: '#803333', borderRadius: '8px' }}>PPG Paints</div> </a>
						</div>

					</div>
				</div>



			</section>
			<section className='hidden md:flex md:items-center md:justify-center md:px-[13%]'>
				<div class="  col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-2 ">
					<section className="hero-wrap hero-wrap-2" style={{ backgroundImage: `url(${Hh1})`, height: '100%' }}>
						<div className="overlay h-full" style={{ background: 'rgba(0, 0, 0, 0.7)', opacity: 1 }} >

							<div className="container ">
								<div className="row no-gutters slider-text align-items-end p-5 justify-content-center" >
									<div className=" ftco-animate mb-2 text-center py-4 text-white " >
										<h2 className=" "><div className='text-white  capitalize font-extrabold pb-3'>Shop In-Store</div></h2>
										<p class="content-c">Our knowledgeable staff is ready to assist you in selecting the right flooring to ensure your satisfaction for years to come.</p>

										<a href='/contact' > <div className='btn  text-white w-fit' style={{ backgroundColor: '#803333', borderRadius: '8px' }}>Contact Us</div> </a>
									</div>
								</div>
							</div>
						</div>

					</section>
				</div>
				<div class=" col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-2">
					<section className="hero-wrap hero-wrap-2" style={{ backgroundImage: `url(${Hh2})`, height: '100%' }}>
						<div className="overlay h-full" style={{ background: 'rgba(0, 0, 0, 0.7)', opacity: 1 }} >

							<div className="container ">
								<div className="row no-gutters slider-text align-items-end p-5 justify-content-center" >
									<div className=" ftco-animate mb-2 text-center py-4 text-white " >
										<h2 className=" "><div className='text-white  capitalize font-extrabold pb-3'>Shop At Home</div></h2>
										<p class="content-c">Don't have time to stop by our showroom? We can bring samples right to your front door. Just schedule an appointment to get started.</p>

										<a href='/contact' > <div className='btn  text-white w-fit' style={{ backgroundColor: '#803333', borderRadius: '8px' }}>Get Started</div> </a>
									</div>
								</div>
							</div>
						</div>

					</section>
				</div>

			</section>
			<div className='bg-[#323232]'>
				<section className='container'>
					<div class="row p-3" >
						<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-2">
							<img src={NewHome} alt="Los Angeles" class="d-block" style={{ borderRadius: '5%' }} />
						</div>
						<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 flex items-center justify-center px-4" style={{
							paddingInline: '5% 10%'
						}}>
							<div className='jumbotron text-white' >
								<p class="h3 font-bold">A Third Generation Family-Owned Business</p>
								<div className='row'>
									<div class="col-md-12">
										<div class="card-box-c foo" style={{ padding: '20px' }}>
											<div class="card-body-c align-self-center">
												<p class="content-c ">We opened our doors in 1960, as a small local family paint store. Since then, O'Fallon Decorating has grown into a business that offers paint, as well as, a sales and installation of a full line of hardwood, carpet, tile, and vinyl flooring. We also offer window treatments, including blinds.</p>
												<p class="content-c">What has kept us in business for all these years remains the same today -- Our knowledgeable staff puts you the customer first, we understand our products, and we're committed to bringing you and end result that you'll love for years to come.</p>
												<p class="lead">
													<a href="/about-us" class="btn btn-dark btn-lg active" role="button" style={{ backgroundColor: '#803333', borderRadius: '8px' }}>Explore More About Us</a>
												</p>

											</div>
										</div>
									</div>

								</div>
							</div>

						</div>
					</div>
				</section >
			</div>
			<section class="p-4 " style={{ backgroundColor: '#ffffff' }}>
				<div className='container'>
					<div class="row flex content-center">
						<div class="col-md-4 col-sm-6 p-1 col-12">
							<div class="card-box-c foo p-4 bg-white text-black" style={{ borderRadius: '15px', height: '100%', borderBlockColor: '#803333' }}>
								<div class="card-header-c ">
									<div class="card-box-ico">
										<span ><i class="fa-sharp fa fa-house"><img src={home1} alt="Los Angeles" class="d-block rounded" /></i></span>
									</div>
									<div class="card-title-c">
										<h2 class=""> Reviews</h2>
									</div>
								</div>
								<div class="card-body-c ">
									<p class="content-c ">
									  The O' Fallon, MO area trusts O Fallon Decorating for all their paints and flooring needs.
									</p>
									<a href='/reviews'> <div className='btn btn-dark w-100' style={{ backgroundColor: '#803333', borderRadius: '8px' }}> Our Reviews</div> </a>
								</div>
							</div>

						</div>
						<div class="col-md-4 col-sm-6 col-6 p-1 col-12" >
							<div class="card-box-c foo p-4 bg-white text-black" style={{ borderRadius: '15px', height: '100%' }}>
								<div class="card-header-c ">
									<div class="card-box-ico">
										<span ><i class="fa-sharp fa fa-house"><img src={home2} alt="Los Angeles" class="d-block rounded" /></i></span>
									</div>
									<div class="card-title-c">
										<h2 class=" "> Request</h2>
									</div>
								</div>
								<div class="card-body-c ">
									<p class="content-c  ">
										Request your accurate and professional in-home flooring estimate.
									</p>
									<div class="mt-auto">
										<a href='/free-estimate' class='btn btn-dark w-100' style={{ backgroundColor: '#803333', borderRadius: '8px' }}>Schedule Now</a>
									</div>								</div>
							</div>

						</div>
						<div class="col-md-4 col-sm-6 p-1 col-12">
							<div class="card-box-c foo p-4 bg-white text-black" style={{ borderRadius: '15px', height: '100%' }}>
								<div class="card-header-c ">
									<div class="card-box-ico">
										<span ><i class="fa-sharp fa fa-house"><img src={home6} alt="Los Angeles" class="d-block rounded" /></i></span>
									</div>
									<div class="card-title-c">
										<h2 class=" "> Services</h2>
									</div>
								</div>
								<div class="card-body-c ">
									<p class="content-c ">
									  Our team of professionals are ready to help you with your next project.
									</p>
									<a href='/flooring-services'> <div className='btn btn-dark w-100' style={{ backgroundColor: '#803333', borderRadius: '8px' }}> what we Offer</div> </a>
								</div>
							</div>

						</div>
					</div>
				</div>

			</section>
			<div className='bg-[#323232]'>
				<section className='container p-5'>
					<div class="row rounded">
						<div class="col-lg-4 col-md-12 mb-4 mb-lg-0  text-white rounded pr-4" >
							<p className='text-left ' style={{ paddingTop: '80px' }}> <h1>Get inspired</h1></p>
							<p className='text-left'>We've got you covered flooring, carpet, hardwood, tile, LVP, Laminate, paints, painting supplies, stains, and blinds</p>
							<a href="/inspiration" className='btn w-full p-2 text-white' style={{ backgroundColor: '#803333' }}> View our Galleries</a>
						</div>

						<div class="col-lg-8 mb-8 mb-lg-0">
							<img src={i1} alt="Los Angeles" class="d-block rounded" />


						</div>


					</div>
				</section>
			</div>
			<div><Request /></div>
			<section className='p-4 container border-t-2 py-2'>
				<div className='text-center font-bold'>
					<span style={{ fontSize: '25px' }}>Featured brands & technologies</span>

				</div>
				<section>
					<div >
						<img
							src={brand1}
							alt="brands"
							className="mx-auto "
						/>
					</div>
				</section>

			</section>
			<div><Footer /></div>


		</div>
	);
}

export default Home;
