import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import Request from './Component/requestEstimate';
import s11 from './Component/s11.png';
import s12 from './Component/s12.png';

function Contact() {
    return (
        <div>
            <div><Headers /> </div>
            <section className="hero-wrap hero-wrap-2" style={{ backgroundImage: `url(${s11})` }} data-stellar-background-ratio="0.5">
                <div className="overlay" style={{ background: 'rgba(0, 0, 0, 0.3)' }}>
                    <div className="container">
                        <div className="row no-gutters slider-text align-items-end justify-content-center">
                            <div className="col-md-9 ftco-animate mb-2 text-center p-5 text-white ">
                                <h2 className=" "><div className='text-white  capitalize'>PPG Paints</div></h2>
                                <p class="mb-2 text-lg font-normal text-white lg:text-xl sm:px-16 xl:px-48 " >{' '} </p>

                                <a href='/free-estimate'> <div class="btn btn-dark btn-lg active" role="button" style={{ backgroundColor: '#803333', borderRadius: '8px' }}>Request an Estimate</div> </a>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <div className=' container flex  justify-center items-center'>
            <div className=' p-5' >
                        <h2 class=" font-bold">Exterior Paint Needs</h2>
                        <div className='row'>
                            <div class="col-md-12">
                                <div class="card-box-c foo" style={{ borderRadius: '5px' }}>
                                    <div class="card-body-c align-self-center">
                                        
                                        <p class="content-c">Exterior surfaces need paint that offers added benefits and characteristics to deal with conditions not part of an indoor experience. In addition, these products offer advantages you can use, like urethane-modified products that offer warranties you can trust for years. Whether using a brush, roller, or sprayer, you'll enjoy the consistent, professional results of PPG exterior paint.
</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <h4 class=" font-bold">What Else To Know?</h4>
                        <div className='row'>
                            <div class="col-md-12">
                                <div class="card-box-c foo" style={{ borderRadius: '5px' }}>
                                    <div class="card-body-c align-self-center">
                                       

                                        <p class="content-c">
                                        PPG Paints offers many products created to serve homeowners and business owners with long-lasting results. Consider this product line for stains, moisture protection, primers, and sealers, that cater to all your remodeling plans any time of year. If you have questions about these products, their warranties, or the company that stands behind them, feel free to visit our showroom and speak with a paint specialist today.                                        </p>

                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* <p class="lead">
                            <a href="/free-estimate" class="btn btn-dark btn-lg active" role="button" style={{ borderRadius: '15px' }}>Request an Estimate</a>
                        </p>       */}
                          </div>

            </div>
            <div className='container'>
            <div className='row  justify-center items-center'>
                <div className='col-md-6  '><img
								src={s12}
								alt="brands"
								className="mx-auto "
							/> </div>
                <div className='col-md-6 '>
                    <div className=' mt-5 pr-[20px] p-2' >
                        <h4 class=" font-bold">Trust Us With Your Painting Needs</h4>
                        <div className='row'>
                            <div class="col-md-12">
                                <div class="card-box-c foo" style={{ borderRadius: '5px' }}>
                                    <div class="card-body-c align-self-center">
                                        <p class="content-c">O’Fallon Decorating Center has been locally and family-owned since 1960, and we've come a long way since then, growing into a one-stop interior and exterior paint shop for all your decorating needs. We have a knowledgeable staff of associates who always put the customer first, and we understand that your needs are ours. We're committed to giving you the perfect products to create the results and remodel you want and need, whether large or small.</p>

                                        <p class="content-c">
                                        You can visit our showroom in O'Fallon, MO, at your convenience to browse a vast inventory of materials and speak with us about the services that put them into place. We serve residents from O'Fallon, MO, Saint Charles, MO, Saint Louis, MO, Lake St Louis, MO, and Wentzville, MO, and we'll provide your PPG paints as well. So, stop by today to speak with an associate to share your requirements and vision, and let us take care of the rest.                       </p>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <p class="lead">
                            <a href="/free-estimate" class="btn btn-dark btn-lg active" role="button" style={{ backgroundColor: '#803333', borderRadius: '8px' }}>Request an Estimate</a>
                        </p>        </div>

                </div>

            </div>
            </div>


            <div><Footer /> </div>
        </div>
    )
}

export default Contact
