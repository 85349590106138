import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import Request from './Component/requestEstimate';
import about from './Component/about.png';
import brand1 from './Component/home/brand1.png';
import home6 from './Component/home/home3.jpg';
import home1 from './Component/home/home1.jpg';
import home2 from './Component/home/home2.jpg';
import NewHome from './Component/newhome.png'
import Aa1 from './Component/home/aa1.png'
import Aa2 from './Component/home/aa2.png'
import Aa3 from './Component/home/aa3.png'
import Award from './Component/home/award1.png'

function About() {
  return (
    <div>
      <div><Headers /> </div>
      <div className='bg-[#323232]'>
				<section className='container'>
					<div class="row p-3" >
						<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-2">
							<img src={Aa1} alt="Los Angeles" class="d-block" style={{ borderRadius: '5%' }} />
						</div>
						<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 flex items-center justify-center px-4" style={{
							paddingInline: '5% 10%'
						}}>
							<div className='jumbotron text-white' >
								<p class="h3 font-bold">Local And Family Owned For 60 Years And Counting</p>
								<div className='row'>
									<div class="col-md-12">
										<div class="card-box-c foo" style={{ padding: '20px' }}>
											<div class="card-body-c align-self-center">
												<p class="content-c ">We opened our doors in 1960, as a small local family paint store. Since then, O'Fallon Decorating has grown into a business that offers paint, as well as, a sales and installation of a full line of hardwood, carpet, tile, and vinyl flooring. We also offer window treatments, including blinds.</p>
												<p class="content-c">What has kept us in business for all these years remains the same today -- Our knowledgeable staff puts you the customer first, we understand our products, and we're committed to bringing you and end result that you'll love for years to come.

We also work with some of the best painting contractors and remodelers in the area. If you need a recommendation, feel free to give us a call, and we'll help you get your project started.</p>
												<p class="lead">
													<a href="/contact" class="btn btn-dark btn-lg active" role="button" style={{ backgroundColor: '#803333', borderRadius: '8px' }}>Contact Us</a>
												</p>

											</div>
										</div>
									</div>

								</div>
							</div>

						</div>
					</div>
				</section >
        
			</div>
			<section class="p-4 " style={{ backgroundColor: '#ffffff' }}>
				<div className='container'>
					<div class="row flex content-center">
						<div class="col-md-4 col-sm-6 p-1 col-12">
							<div class="card-box-c foo p-4 bg-white text-black" style={{ borderRadius: '15px', height: '100%', borderBlockColor: '#803333' }}>
								<div class="card-header-c ">
									<div class="card-box-ico">
										<span ><i class="fa-sharp fa fa-house"><img src={home1} alt="Los Angeles" class="d-block rounded" /></i></span>
									</div>
									<div class="card-title-c">
										<h2 class=""> Reviews</h2>
									</div>
								</div>
								<div class="card-body-c ">
									<p class="content-c ">
										The Claysburg, PA area trusts Carpet Depot Home Center for all of their flooring needs.
									</p>
									<a href='/reviews'> <div className='btn btn-dark w-100' style={{ backgroundColor: '#803333', borderRadius: '8px' }}> Our Reviews</div> </a>
								</div>
							</div>

						</div>
						<div class="col-md-4 col-sm-6 col-6 p-1 col-12" >
							<div class="card-box-c foo p-4 bg-white text-black" style={{ borderRadius: '15px', height: '100%' }}>
								<div class="card-header-c ">
									<div class="card-box-ico">
										<span ><i class="fa-sharp fa fa-house"><img src={home2} alt="Los Angeles" class="d-block rounded" /></i></span>
									</div>
									<div class="card-title-c">
										<h2 class=" "> Request</h2>
									</div>
								</div>
								<div class="card-body-c ">
									<p class="content-c  ">
										Request your accurate and professional in-home flooring estimate.
									</p>
									<div class="mt-auto">
										<a href='/free-estimate' class='btn btn-dark w-100' style={{ backgroundColor: '#803333', borderRadius: '8px' }}>Schedule Now</a>
									</div>								</div>
							</div>

						</div>
						<div class="col-md-4 col-sm-6 p-1 col-12">
							<div class="card-box-c foo p-4 bg-white text-black" style={{ borderRadius: '15px', height: '100%' }}>
								<div class="card-header-c ">
									<div class="card-box-ico">
										<span ><i class="fa-sharp fa fa-house"><img src={home6} alt="Los Angeles" class="d-block rounded" /></i></span>
									</div>
									<div class="card-title-c">
										<h2 class=" "> Services</h2>
									</div>
								</div>
								<div class="card-body-c ">
									<p class="content-c ">
										Our professional flooring installers are ready to help you with your next project.
									</p>
									<a href='/flooring-services'> <div className='btn btn-dark w-100' style={{ backgroundColor: '#803333', borderRadius: '8px' }}> what we Offer</div> </a>
								</div>
							</div>

						</div>
					</div>
				</div>

			</section>
      <div className='bg-[#323232]'>
				<section className='container'>
					<div class="row p-3" >
						
						<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 flex items-center justify-center px-4" style={{
							paddingInline: '5% 10%'
						}}>
							<div className='jumbotron text-white' >
								<p class="h3 font-bold">Why Should You Choose Us?</p>
								<div className='row'>
									<div class="col-md-12">
										<div class="card-box-c foo" style={{ padding: '20px' }}>
											<div class="card-body-c align-self-center">
												<p class="content-c ">Commitment to excellence </p>
                        <p class="content-c ">Helpful staff </p>
                        <p class="content-c "> Highest quality service</p>
                        <p class="content-c ">Knowledgeable staff </p>
                        <p class="content-c "> Licensed installers</p>
                        <p class="content-c ">One stop shop </p>
											</div>
										</div>
									</div>

								</div>
							</div>

						</div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-2">
							<img src={Aa2} alt="Los Angeles" class="d-block" style={{ borderRadius: '5%' }} />
						</div>
					</div>
				</section >
        
			</div>
        <section className='p-4 container border-t-2 py-6'>
        <div className='text-center font-bold'>
          <span style={{ fontSize: '25px' }}>Awards & Associations</span>
        </div>
        <section>

					<div >
							<img
								src={Award}
								alt="brands"
								className="mx-auto "
							/>
						</div>
			</section>

      </section>
      <div className='bg-[#323232]'>
				<section className='container'>
					<div class="row p-3" >
						
						<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 flex items-center justify-center px-4">
							<div className='jumbotron text-white' >
								<p class="h3 font-bold">Proudly Serving In And Around The Following Areas:</p>
								<div className='row'>
									<div class="col-md-12">
										<div class="card-box-c foo">
											<div class="card-body-c align-self-center">
												<p class="content-c "> O' Fallon</p>
                        <p class="content-c ">St. Charles County</p>
                        <p class="content-c ">St. Louis County </p>
                        <p class="content-c "> Warren County</p>
                        <p class="content-c ">Lincoln County </p>
                        <p class="content-c ">Wentzville </p>
                        <p class="content-c ">Lake St. Louis </p>
                        <p class="content-c ">St. Peters </p>
												<p class="lead">
													<a href="/free-estimate" class="btn btn-dark btn-lg active" role="button" style={{ backgroundColor: '#803333', borderRadius: '8px' }}>Request Estimate</a>
												</p>

											</div>
										</div>
									</div>

								</div>
							</div>

						</div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-2">
							<img src={Aa3} alt="Los Angeles" class="d-block" style={{ borderRadius: '5%' }} />
						</div>
					</div>
				</section >
        
			</div>
        
      <div><Request /></div>

      <section className='p-4 container border-t-2 py-2'>
        <div className='text-center font-bold'>
          <span style={{ fontSize: '25px' }}>Featured brands & technologies</span>
        </div>
        <section>
					<div >
							<img
								src={brand1}
								alt="brands"
								className="mx-auto "
							/>
						</div>
			</section>

      </section>
      <div><Footer /> </div>
    </div>
  )
}

export default About
