import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import Request from './Component/requestEstimate';
import s2 from './Component/starrr.png';

function Contact() {
  return (
    <div>
      <div><Headers /> </div>
      <div className='text-center font-bold p-4 container'>
        <span style={{ fontSize: '35px' }}>Contact us</span> <br />
        <small className='justify'>GET IN TOUCH WITH THE FLOORING PROFESSIONALS AT O’FALLON DECORATING CENTER</small>
      </div>
      <div className='row '>
        <div className='col-md-8'><Request /> </div>
        <div className='col-md-3 p-4'>
          <div className="max-w-md mx-auto mt-8 p-8 bg-white rounded-md shadow-md">
            <div className="mb-4">
              <h2 className="text-2xl font-bold ">Store Info</h2>
              <p className="text-gray-600 text-2xl ">Address</p>
              <p className="text-gray-800">
              851 West Terra Lane
O'Fallon , MO 63366
              </p>
              <a href="https://www.google.com/maps/place/O'Fallon+Decorating+Center/@38.8034091,-90.7213326,15z/data=!4m6!3m5!1s0x87ded0643d68b703:0xe0ec3cbd16d545a6!8m2!3d38.8034091!4d-90.7213326!16s%2Fg%2F1tf6606d?entry=ttu" target="_blank" class="btn btn-dark btn-lg active d-flex justify-content-center" role="button" style={{ borderRadius: '15px' }} rel="noreferrer">Get directions</a>

            </div>


            <div>
              <p className="text-gray-600">Phone</p>
              <p className="text-gray-800">(636) 240-5220</p>
            </div>

            <div className="mb-4">
              <a href="tel:+16362405220" class="btn btn-dark btn-lg active d-flex justify-content-center" role="button" style={{ borderRadius: '15px' }} >Call us</a>

            </div>

            <div className="mb-4">
              <p className="text-gray-600">Hours</p>
              <p className="text-gray-800">
                Mon-Fri 7:30 am - 5pm <br />
                Sat 8am - 2pm <br />
                Sun Closed
              </p>
            </div>

            <div>
              <a href="/free-estimate" class="btn btn-dark btn-lg active d-flex justify-content-center" role="button" style={{  borderRadius: '15px' }} >Request an estimate</a>

            </div>
          </div>
        </div>

      </div>
      <div class="container py-5">
        <a href='/reviews'><img src={s2} alt="Los Angeles" class="d-block rounded" /></a>

      </div>
      <div class="container">
        <div class="map-responsive">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12437.072530261828!2d-90.7213326!3d38.8034091!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87ded0643d68b703%3A0xe0ec3cbd16d545a6!2sO&#39;Fallon%20Decorating%20Center!5e0!3m2!1sen!2sin!4v1709299585917!5m2!1sen!2sin" title="example" width="100%" height="450" frameborder="0"  allowfullscreen />
        </div>
      </div>
      <div><Footer /> </div>
    </div>
  )
}

export default Contact
