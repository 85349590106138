import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import home2 from './Component/s3.png';
import s2 from './Component/s2.png';
import Form from './Component/requestEstimate';

function About() {
	return (
		<div>
			<div><Headers /> </div>
			<div className='container'>
				<div className='text-center font-bold p-4'>
					<span style={{ fontSize: '35px' }}>Services</span> <br />
					<small className='justify'> SEE WHY WE'RE THE O'FALLON, MO AREA'S MOST TRUSTED SERVICE PROVIDER</small>
				</div>
				<section >
					<div class="row p-3" >
						<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
							<img src={home2} alt="Los Angeles" class="d-block rounded" />
						</div>
						<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
							<ul className='jumbotron' >
								<li class=" font-bold text-slate-500 py-2">Blinds sales & installation</li>
								<li class=" font-bold text-slate-500 py-2">Commercial & residential installations</li>
								<li class=" font-bold text-slate-500 py-2">Custom showers</li>
								<li class=" font-bold text-slate-500 py-2">Design consultation</li>
								<li class=" font-bold text-slate-500 py-2">Flooring installation</li>
								<li class=" font-bold text-slate-500 py-2">Free in-home estimates</li>
								<li class=" font-bold text-slate-500 py-2">Hardwood refinishing</li>
								<li class="">
									<a href="/free-estimate" class="btn btn-lg active text-white" role="button" style={{ borderRadius: '15px', backgroundColor: '#803333' }}>Request an Estimate</a>
								</li>
							</ul>

						</div>
					</div>
				</section >
			</div>
			<div><Form /></div>
			<div><Footer /> </div>
		</div>
	)
}

export default About
